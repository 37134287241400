<template>
	<v-dialog :value="true" min-width="300" max-width="400" persistent noClickAnimation overlay-opacity="0.9" overlay-color="black">

		<v-card>
			<v-card-title>Snooze Task</v-card-title>
			<v-card-text>
				<div class="field-list">
					<div class="input-group input-group-sm">
						<v-text-field v-model="date" :min="minDate" label="Hide Until" type="date" dense hide-details outlined></v-text-field>
					</div>
					<div class="error pa-2 mt-1" v-show="!valid">Please choose a date in the future</div>
				</div>

			</v-card-text>
			<v-card-actions>
				<v-btn class="ml-auto" @click="modal.trigger('close')">Close</v-btn>
				<v-btn color="primary" @click="submit" :disabled="!valid">Continue</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	import moment from "moment";

	export default {
		name: "snoozeTask",

		props: {
			modal: {
				type: Object,
				required: true,
			},
			task: {
				type: Object,
				required: true,
			},
		},

		data() {
			return {
				date: new moment().add(7, "d").format("YYYY-MM-DD"),
			};
		},

		computed: {
			minDate() {
				return new moment().add(1, "d").format("YYYY-MM-DD");
			},
			valid() {
				let d = new moment(this.date);
				return d.isValid() && d.isAfter(new moment());
			},
		},

		methods: {
			submit() {
				if (!this.valid) {
					this.date = null;
					return;
				}

				this.modal.trigger("close");
				this.modal.trigger("save", this.task, this.date);
			},
		},
	};
</script>